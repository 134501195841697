import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userRole: null,
  accessList: [],
  accessToken: '',
  user: {
    name: '',
    designation: '',
    imagePath: '',
  },
  currentLanguage: {},
  dashboardActive: '',
  franchiseId: '',
  toggleNotifications: false,
  notificationsCount: 0,
  saasToken: null,
  franchiseInfo: null,
  accessControlPermissions: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserRole(state, { payload: userRole }) {
      state.userRole = userRole;
    },
    setUserAccessList(state, { payload: accessList }) {
      state.accessList = accessList;
    },
    setAccessControlPermissions(state, { payload: accessControlList }) {
      state.accessControlPermissions = accessControlList;
    },
    setAccessToken(state, { payload: accessToken }) {
      state.accessToken = accessToken;
    },
    setCurrentLanguage(state, { payload: currentLanguage }) {
      state.currentLanguage = currentLanguage;
    },
    setDashboardActive(state, { payload: dashboardActive }) {
      state.dashboardActive = dashboardActive;
    },
    setFranchiseId(state, { payload: franchiseId }) {
      state.franchiseId = franchiseId;
    },
    setFranchiseInfo(state, { payload: franchiseInfo }) {
      state.franchiseInfo = franchiseInfo;
    },
    toggleNotificationReceived(state) {
      state.toggleNotifications = !state.toggleNotifications;
    },
    setNotificationsCountRedux(state, { payload }) {
      state.notificationsCount = payload;
    },
    setSaasToken(state, { payload }) {
      state.saasToken = payload;
    },
    setFranchiseTimeZone(state, { payload }) {
      state.franchiseTimeZone = payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {},
});

export const {
  setUserRole,
  setUserAccessList,
  setAccessToken,
  setCurrentLanguage,
  setDashboardActive,
  setFranchiseId,
  setNotificationsCountRedux,
  setSaasToken,
  toggleNotificationReceived,
  setFranchiseInfo,
  setFranchiseTimeZone,
  setAccessControlPermissions,
} = authSlice.actions;

export default authSlice.reducer;
